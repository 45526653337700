import React from 'react'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Footer from 'components/Footer'
import Item from './Item'

const MOCK_CAREERS = [
  {
    id: 11,
    type: 'Anywhere · Full time',
    position: 'Product Education',
    link: 'https://angel.co/company/agency-vista/jobs/946586-product-education-specialist',
    description:
      'We are looking for an all-star Customer Education Specialist to join our team. Your main objective will be to increase the system use across our customers and to answer their questions.',
  },
  {
    id: 12,
    type: 'Anywhere · Full time',
    position: 'Content Specialist',
    link: 'https://angel.co/company/agency-vista/jobs/647843-content-specialist-digital-marketer',
    description: `Are you a writer who’s passionate about the role of content in building brand awareness, generating new business and making a real business impact? 
      In this highly collaborative role, you’ll bring a strategic and creative mindset to creating content like blog posts, guides, lead generation resources and more. `,
  },
  {
    id: 13,
    type: 'Anywhere · Full time',
    position: 'Software Engineer',
    link: 'https://angel.co/company/agency-vista/jobs/647846-full-stack-javascript-developer',
    description: `We are looking to build many amazing features for our users! If you enjoy coding, designing, solving challenging problems and most importantly dreaming - then we want you to apply! Technology-wise - we do want you to be a full-stack JavaScript coder and have experience working with cloud technologies. `,
  },
]

const Careers = () => (
  <App>
    <SEO
      title="Careers"
      description="We’re looking for passionate, hardworking people to join team Sprout. Find careers in marketing, sales, design and engineering."
      path="/careers/"
    />
    <Box px="m">
      <Flex mt="xl" alignItems="center" flexDirection="column">
        <H1>Want to join in on the fun?</H1>
        <Text mt="m" fontSize="l" color="secondaryText">
          If you enjoy social media, constantly learning, and are a pro in GSD ("getting stuff done") - then we want you
          to apply!
        </Text>
      </Flex>
      <Flex justifyContent="center">
        <Flex mt="xxl" maxWidth="1200px">
          <Grid gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="l">
            {MOCK_CAREERS.map((item) => (
              <Item key={item.id} {...item} />
            ))}
          </Grid>
        </Flex>
      </Flex>
      <Box mt="xxl">
        <Footer />
      </Box>
    </Box>
  </App>
)

export default Careers
