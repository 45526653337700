import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, radius, COLOR_CONSTANTS } from 'theme'
import { Flex, Box } from 'components/Layout'
import { H2, Text } from 'components/Typography'
import Button from 'components/Button'
import Link from 'components/Link'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${space.l};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${radius.m};
  box-shadow: 0px 20px 40px rgba(39, 40, 49, 0.05);
  position: relative;
`

const Item = ({ type, position, description, link }) => (
  <Wrapper>
    <Text color="secondaryText">{type}</Text>
    <Box my="m" minHeight={{ desktop: '84px' }}>
      <H2>{position}</H2>
    </Box>
    <Text color="secondaryText" mb="l">
      {description}
    </Text>
    <Flex maxWidth="200px" alignSelf="center" position="absolute" bottom={space.m}>
      <Button.Primary as={Link} href={link} target="_blank">
        <Text color="white">Apply Now</Text>
      </Button.Primary>
    </Flex>
  </Wrapper>
)

Item.propTypes = {
  type: PropTypes.string.isRequired,
}

export default Item
